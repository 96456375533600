<template>
    <div
        class="relative z-50"
        :class="{
            'sidebar--open': sidebarOpen,
        }">
        <div
            class="block lg:hidden sidebar__backpage opacity-0 fixed bg-white pointer-events-none h-screen w-screen z-0"
            :ref="'sidebarBackpage'"
            @click="closeSidebar"></div>
        <div
            :ref="'sidebar'"
            class="fixed sidebar w-sideBarWidth lg:w-sideBarWidthLg xl:w-sideBarWidthXl bottom-0 left-0 top-0 lg:top-16 pointer-events-none lg:pointer-events-auto opacity-0 lg:opacity-100 transform -translate-x-full lg:translate-x-0">
            <div class="w-full h-full shadow-2xl lg:shadow-none overflow-y-auto bg-gray-100 overscroll-contain">
                <transition
                    @beforeEnter="slideFadeBeforeEnter"
                    @enter="slideFadeEnter"
                    @leave="slideFadeLeave"
                    mode="out-in"
                    :css="false">
                    <AppSidebarLoader v-if="isLoadingFileTree" />
                    <nav
                        v-else
                        class="p-6 md:py-10">
                        <div
                            class="mb-8">
                            <h5 class="flex flex-row items-center mb-3 lg:mb-2 text-gray-500 uppercase tracking-wide font-bold text-sm lg:text-xs">
                                <component :is="navSectionLearn.sectionIcon" class="h-4 mr-1 fill-current" />{{ navSectionLearn.sectionTitle }}
                            </h5>
                            <AppSidebarNavLink
                                v-for="(link, idx) in navSectionLearn.sectionLinks"
                                :key="`section::links::learn::${idx}`"
                                :link="link" />
                        </div>
                        <div class="mb-8">
                            <h5 class="flex flex-row items-center mb-3 lg:mb-2 text-gray-500 uppercase tracking-wide font-bold text-sm lg:text-xs">
                                <bookSvg class="h-4 mr-2 fill-current" />API Reference
                            </h5>
                            <DocsFileBrowser :filePaths="docsFileTree" />
                        </div>
                        <div
                            class="mb-8">
                            <h5 class="flex flex-row items-center mb-3 lg:mb-2 text-gray-500 uppercase tracking-wide font-bold text-sm lg:text-xs">
                                <component :is="navSectionTools.sectionIcon" class="h-5 mr-1 fill-current" />{{ navSectionTools.sectionTitle }}
                            </h5>
                            <AppSidebarNavLink
                                v-for="(link, idx) in navSectionTools.sectionLinks"
                                :key="`section::links::tools::${idx}`"
                                :link="link" />
                        </div>
                        <div
                            class="mb-8">
                            <h5 class="flex flex-row items-center mb-3 lg:mb-2 text-gray-500 uppercase tracking-wide font-bold text-sm lg:text-xs">
                                <component :is="navSectionSupport.sectionIcon" class="h-5 mr-1 fill-current" />{{ navSectionSupport.sectionTitle }}
                            </h5>
                            <AppSidebarNavLink
                                v-for="(link, idx) in navSectionSupport.sectionLinks"
                                :key="`section::links::support::${idx}`"
                                :link="link" />
                        </div>
                    </nav>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';

import { mapState } from 'vuex';

import { EventBus } from '@/utils';

import transitions from '@/components/mixins/transitions';
import AppSidebarLoader from '@/components/ui/AppSidebarLoader.vue';

export default {
    name: 'AppSidebar',

    mixins: [transitions],

    props: {
        isOpen: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        const isMedium = window.innerWidth < 1100;
        const sidebarOpen = isMedium ? false : this.isOpen;

        return {
            isMedium,
            sidebarOpen,

            navSectionLearn: {
                sectionTitle: 'Learn',
                sectionIcon: 'schoolSvg',
                sectionLinks: [
                    {
                        path: '/tutorials',
                        title: 'Tutorials',
                    },
                    // {
                    //     path: '/screencasts',
                    //     title: 'Screencasts',
                    // },
                ],
            },

            navSectionTools: {
                sectionTitle: 'Tools',
                sectionIcon: 'settingsSvg',
                sectionLinks: [
                    {
                        path: '/sdks',
                        title: 'SDKs',
                    },
                    {
                        path: '/simulator',
                        title: 'Simulator',
                    },
                    {
                        path: 'https://www.postman.com/africastalking',
                        title: 'Postman Collections',
                    },
                ],
            },

            navSectionSupport: {
                sectionTitle: 'Support',
                sectionIcon: 'helpSvg',
                sectionLinks: [
                    {
                        path: 'https://slack.africastalking.com',
                        title: 'Slack',
                    },
                    {
                        path: 'https://github.com/AfricasTalkingLtd',
                        title: 'Github',
                    },
                    {
                        path: 'https://help.africastalking.com',
                        title: 'Help Center',
                    },
                ],
            },
        };
    },

    computed: {
        ...mapState('docs', [
            'isLoadingFileTree',
            'docsFileTree',
        ]),
    },

    watch: {
        $route() {
            this.closeSidebarIfMedium();
        },
    },

    created() {
        this.registerBusListeners();
    },

    beforeDestroy() {
        this.cancelBusListeners();
    },

    methods: {
        openSidebar() {
            if (this.sidebarOpen) return;

            const sidebarTimeline = gsap.timeline({
                onComplete: () => {
                    this.sidebarOpen = true;
                },
            });

            sidebarTimeline
                .to(this.$refs.sidebar, {
                    duration: 0.3,
                    translateX: 0,
                    opacity: 1,
                    ease: 'in',
                })
                .to(this.$refs.sidebarBackpage, {
                    duration: 0.2,
                    opacity: 0.7,
                    ease: 'in',
                }, '-=0.2');
        },

        closeSidebar({ delay } = { delay: 0 }) {
            if (!this.sidebarOpen) return;

            const sidebarTimeline = gsap.timeline({
                onComplete: () => {
                    this.sidebarOpen = false;
                },
                delay,
            });

            sidebarTimeline
                .to(this.$refs.sidebar, {
                    duration: 0.3,
                    translateX: '-105%',
                    opacity: 0.8,
                    ease: 'out',
                })
                .to(this.$refs.sidebarBackpage, {
                    duration: 0.2,
                    opacity: 0,
                    ease: 'out',
                }, '-=0.2');
        },

        closeSidebarIfMedium() {
            if (this.isMedium) {
                this.closeSidebar({ delay: 0.15 });
            }
        },

        registerBusListeners() {
            EventBus.$on('openSidebar', this.openSidebar);
        },

        cancelBusListeners() {
            EventBus.$off('openSidebar', this.openSidebar);
        },
    },

    components: {
        AppSidebarLoader,
        AppSidebarNavLink: () => import('@/components/ui/AppSidebarNavLink.vue'),
        DocsFileBrowser: () => import('@/components/docs/DocsFileBrowser.vue'),
        bookSvg: () => import('@/assets/img/book.svg'),
        helpSvg: () => import('@/assets/img/help.svg'),
        settingsSvg: () => import('@/assets/img/settings.svg'),
        schoolSvg: () => import('@/assets/img/school.svg'),
    },
};
</script>

<style lang="postcss">
:root {
    --sideBarWidth: 20rem;
    --sideBarWidthLg: 16rem;
    --sideBarWidthXl: 18rem;
}

.sidebar-pl {
    padding-left: 0;
}

@screen lg {
    .sidebar-pl {
        padding-left: var(--sideBarWidthLg);
    }
}

@screen xl {
    .sidebar-pl {
        padding-left: var(--sideBarWidthXl);
    }
}

.sidebar--open {
    & .sidebar {
        @apply translate-x-0 opacity-100 pointer-events-auto;
    }

    & .sidebar__backpage {
        @apply opacity-75 pointer-events-auto;
    }
}

.sidebar-link-active {
    & > .background {
        background-color: theme('colors.orange.300');
        opacity: theme('opacity.25');
    }

    & > .text {
        color: theme('colors.orange.500');
    }
}
</style>
