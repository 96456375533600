<template>
    <div class="px-6 mt-10">
        <ContentLoader
            width="183"
            height="234"
            :speed="2"
            primaryColor="#e2e8f0"
            secondaryColor="#cbd5e0">
            <rect x="26" width="157" height="16" rx="5" />
            <rect y="29" width="183" height="15" rx="5" />
            <rect y="54" width="145" height="15" rx="5" />
            <rect y="79" width="110" height="15" rx="5" />
            <rect width="16" height="16" rx="4" />
            <rect x="26" y="140" width="157" height="16" rx="5" />
            <rect y="169" width="183" height="15" rx="5" />
            <rect y="194" width="145" height="15" rx="5" />
            <rect y="219" width="183" height="15" rx="5" />
            <rect y="140" width="16" height="16" rx="4" />
        </ContentLoader>
    </div>
</template>

<script>
import {
    ContentLoader,
} from 'vue-content-loader';

export default {
    name: 'TutorialsLoader',

    components: {
        ContentLoader,
    },
};
</script>
